<template>
  <v-dialog
    v-if="dutyDate"
    :value="value"
    fullscreen
    persistent
    hide-overlay
    no-click-animation
    content-class="afl-area"
    transition="dialog-bottom-transition"
  >
    <!-- Duty Page Header -->
    <v-app-bar
      color="primary"
      fixed
      class="afl-toolbar"
    >
      <v-toolbar-title class="d-flex fill-width align-center justify-space-between">
        <v-btn @click.prevent="$router.go(-1)" class="back-btn align-self-center">
          <v-icon class="align-middle">
            mdi-chevron-left
          </v-icon>
          <span class="align-middle">Back</span>
        </v-btn>
        <v-btn @click.prevent="ContactOCC('6281312958090')" icon class="text-right align-self-center">
          <v-icon class="align-middle mr-2">
            mdi-help-circle-outline
          </v-icon>
          <span class="align-middle">
            OCC Help
          </span>
        </v-btn>
      </v-toolbar-title>
    </v-app-bar>
    <!-- End of Duty Page Header -->

    <v-card class="afl-card">
      <div class="afl-card-header">
        <v-row no-gutters class="justify-space-between align-center">
          <v-col cols="12" class="align-self-center">
            <v-row no-gutters class="justify-space-between">
              <v-icon color="primary" class="disable-ripple" @click.prevent="dutyDate = $DateAddDays(dutyDate, -1)">
                mdi-chevron-left
              </v-icon>
              <v-dialog
                ref="dialogDutyDate"
                v-model="dialogDutyDate"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    class="duty-date-title"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $moment(dutyDate).format('ddd, MMM D YYYY') }}
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dutyDate"
                  :first-day-of-week="1"
                  @input="dialogDutyDate = false"
                >
                </v-date-picker>
              </v-dialog>
              <v-icon color="primary" class="disable-ripple" @click.prevent="dutyDate = $DateAddDays(dutyDate, 1)">
                mdi-chevron-right
              </v-icon>
            </v-row>
          </v-col>

          <v-col v-if="duty" cols="6" class="start-duty mt-5">
            <v-chip small color="error" class="fw-600">
              Start : {{ duty.start }} LT
            </v-chip>
          </v-col>
          <v-col v-if="duty" cols="6" class="mt-5">
            40m before first flight
          </v-col>
        </v-row>
      </div>

      <div class="afl-card-body">
        <div
          v-touch="{
            left: () => touchTo('left'),
            right: () => touchTo('right')
          }"
          class="fill-width"
        >
          <v-row no-gutters class="duty-card-container">
            <v-col cols="12">
              <div v-if="aflLoading" class="d-flex fill-width align-center justify-center px-4">
                <Loading show="true" color="primary" />
              </div>
              <div v-else-if="!schedules.length" class="grey--text fill-width align-center justify-center px-4 pt-8">
                <img src="./../assets/illustrations/off_day.svg" alt="Time to rest" class="d-block fill-width">
                <div class="font-16 fw-500 mt-8">
                  Enjoy your moment
                </div>
              </div>
              <div v-else>
                <v-card v-for="(s, sS) in schedules" :key="`afl-schedule-${sS}`" class="duty-card">
                  <v-row no-gutters class="justify-space-between">
                    <v-col cols="6" class="align-self-center text-left">
                      <img src="/icon-susiair-h.png" height="18">
                    </v-col>
                    <v-col cols="6" class="align-self-center text-right">
                      <div class="aircraft-reg">{{ s.aircraft_reg }}</div>
                      <span :style="`color:${FlightType(s.flight_type, $store.state.flight_type).color}`" class="text-capitalize">
                        {{ s.flight_type }}
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <div class="dashed-h-border my-2"></div>
                    </v-col>
                    <v-col v-if="s.flight_type !== 'simulator'" cols="6" class="font-13 align-self-start grey--text text-left" @click.prevent="OpenURL('https://www.google.com/maps/@' + s.dep_latitude + ',' + s.dep_longitude + ',1077m/data=!3m1!1e3')">
                      {{ s.dep_cityname }} ({{ s.dep_icao || s.dep_iata }})
                    </v-col>
                    <v-col v-if="s.flight_type !== 'simulator'" cols="6" class="font-13 align-self-start grey--text text-right" @click.prevent="OpenURL('https://www.google.com/maps/@' + s.arr_latitude + ',' + s.arr_longitude + ',1077m/data=!3m1!1e3')">
                      {{ s.arr_cityname }} ({{ s.arr_icao || s.arr_iata }})
                    </v-col>
                    <v-col cols="12" class="align-self-center mt-1">
                      <v-row no-gutters class="justify-space-between">
                        <v-col cols="auto" class="align-self-center text-left" @click.prevent="OpenURL('https://www.google.com/maps/@' + s.dep_latitude + ',' + s.dep_longitude + ',1077m/data=!3m1!1e3')">
                          <div class="etd-time">{{ s.etd_local_time }} LT</div>
                          <div class="font-13 grey--text">
                            {{ $moment(s.etd).format('MMM D YYYY') }}
                          </div>
                        </v-col>
                        <v-col cols="auto" class="align-self-center text-center">
                          <img src="./../assets/icons/flying_progress.svg" alt="Flight Time">
                          <div class="font-13 grey--text">
                            {{ s.flight_type === 'simulator' ? `Duration ${s.flighttime_decimal}` : `Flight Time ${ParseFlightTime(s).flighttime}` }}
                          </div>
                        </v-col>
                        <v-col cols="auto" class="align-self-center text-right" @click.prevent="OpenURL('https://www.google.com/maps/@' + s.arr_latitude + ',' + s.arr_longitude + ',1077m/data=!3m1!1e3')">
                          <div class="etd-time">{{ s.eta_local_time }} LT</div>
                          <div class="font-13 grey--text">
                            {{ $moment(s.eta).format('MMM D YYYY') }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <div class="d-flex align-center fill-width py-2 mt-3">
                    <div class="d-flex flex-column justify-center align-center fill-width px-5">
                      <div v-if="parseInt(s.pilot)" class="d-flex fill-width font-16 fw-500">
                        {{ s.pilot_name }} {{ s.pilot_lastname }}
                      </div>
                      <div class="d-flex fill-width" :class="parseInt(s.pilot) ? 'justify-space-between' : 'justify-end'">
                        <small v-if="parseInt(s.pilot)" class="font-12 grey--text">
                          {{ s.flight_type === 'simulator' ? 'Pilot' : 'Captain' }}
                        </small>
                        <small class="font-12 grey--text">
                          {{ s.flight_type === 'simulator' ? 'Instructor' : 'First Officer' }}
                        </small>
                      </div>
                      <div class="d-flex justify-end fill-width">
                        <span v-if="parseInt(s.copilot)" class="font-16 fw-500">
                          {{ s.copilot_name }} {{ s.copilot_lastname }}
                        </span>
                        <span v-else class="grey--text text--lighten-1">
                          Unassigned
                        </span>
                      </div>
                      <div v-if="s.flight_type === 'simulator'" class="d-flex fill-width mt-1">
                        <small class="font-12 grey--text">
                          Training Assistant
                        </small>
                      </div>
                      <div v-if="s.flight_type === 'simulator'" class="d-flex fill-width">
                        <span v-if="parseInt(s.training_assistant)" class="font-16 fw-500">
                          {{ s.ta_name }} {{ s.ta_lastname }}
                        </span>
                        <span v-else class="grey--text text--lighten-1">
                          Unassigned
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- Simulator logbook for instructor -->
                  <v-expansion-panels v-if="s.flight_type === 'simulator' && s.copilot === $store.state.me.id" :class="[parseInt(s.afl_validatedby_id) ? 'schedule-validated' : '']" flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pa-0">
                        <template v-slot:default="{ open }">
                          <div class="d-flex align-center fill-width">
                            <span class="d-flex align-center pr-2" style="z-index:1;">
                              <v-icon :color="parseInt(s.afl_signedby_id) ? 'secondary' : 'error'" :small="open">
                                {{ parseInt(s.afl_signedby_id) ? 'mdi-check-circle' : 'mdi-information' }}
                              </v-icon>
                              <span v-if="parseInt(s.afl_signedby_id) || DisabledUpdate(s)" class="font-13 grey--text align-middle ml-2">
                                {{ DisabledUpdate(s) && parseInt(s.afl_signedby_id) ? 'Verified' : !DisabledUpdate(s) && parseInt(s.afl_signedby_id) ? 'Saved' : '' }}
                              </span>
                            </span>
                          </div>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row no-gutters class="text-left">
                          <v-col cols="12" class="px-0 mb-3">
                            <label class="afl-input-label">
                              Notes
                            </label>
                            <v-textarea
                              v-model.lazy="schedules[sS].notes"
                              rows="3"
                              placeholder="Write something here..."
                              auto-grow
                              :disabled="DisabledUpdate(s)"
                              hide-details
                              class="afl-notes"
                            />
                          </v-col>
                          <v-col cols="12" class="text-center px-0">
                            <v-btn v-if="!DisabledUpdate(s)"
                              @click.prevent="UpdateSchedule(s, ['afl_takeoff', 'afl_landing', 'notes'])"
                              color="error"
                              :disabled="DisabledUpdate(s)"
                              class="rounded-lg text-capitalize fw-500 px-4 py-2 mt-4 mb-3">
                              {{ DisabledUpdate(s) && parseInt(s.afl_signedby_id) ? 'Data Saved' : !DisabledUpdate(s) && parseInt(s.afl_signedby_id) ? 'Update Data' : 'Complete Simulator' }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <!-- Normal Flight Logbook -->
                  <v-expansion-panels v-else-if="s.flight_type !== 'simulator'" :class="[parseInt(s.afl_validatedby_id) ? 'schedule-validated' : '']" flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pa-0">
                        <template v-slot:default="{ open }">
                          <div class="d-flex align-center fill-width">
                            <span class="d-flex align-center pr-2" style="z-index:1;">
                              <v-icon :color="parseInt(s.afl_signedby_id) ? 'secondary' : 'error'" :small="open">
                                {{ parseInt(s.afl_signedby_id) ? 'mdi-check-circle' : 'mdi-information' }}
                              </v-icon>
                              <span v-if="parseInt(s.afl_signedby_id) || DisabledUpdate(s)" class="font-13 grey--text align-middle ml-2">
                                {{ DisabledUpdate(s) && parseInt(s.afl_signedby_id) ? 'Verified' : !DisabledUpdate(s) && parseInt(s.afl_signedby_id) ? 'Saved' : '' }}
                              </span>
                            </span>
                          </div>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="text-start body-2">
                        <v-row no-gutters>
                          <v-col cols="6" class="pb-3 pr-1">
                            <label class="afl-input-label">
                              Block Off
                            </label>
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_block_off"
                              :disabled="DisabledUpdate(s)"
                              placeholder="00:00"
                              type="tel"
                              maxlength="4"
                              hide-details
                              suffix="UTC"
                              class="afl-input"
                              @keypress="$NumberOnly"
                              @keyup="schedules[sS].afl_block_off = ReformatTime(s.afl_block_off)"
                            />
                          </v-col>
                          <v-col cols="6" class="pb-3 pl-1">
                            <label class="afl-input-label">
                              Takeoff
                            </label>
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_takeoff"
                              :disabled="DisabledUpdate(s)"
                              placeholder="00:00"
                              type="tel"
                              maxlength="4"
                              hide-details
                              suffix="UTC"
                              class="afl-input"
                              @keypress="$NumberOnly"
                              @keyup="schedules[sS].afl_takeoff = ReformatTime(s.afl_takeoff)"
                            />
                          </v-col>
                          <v-col cols="6" class="pb-3 pr-1">
                            <label class="afl-input-label">
                              Landing
                            </label>
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_landing"
                              :disabled="DisabledUpdate(s)"
                              placeholder="00:00"
                              type="tel"
                              maxlength="4"
                              hide-details
                              suffix="UTC"
                              class="afl-input"
                              @keypress="$NumberOnly"
                              @keyup="schedules[sS].afl_landing = ReformatTime(s.afl_landing)"
                            />
                          </v-col>
                          <v-col cols="6" class="pb-3 pl-1">
                            <label class="afl-input-label">
                              Block On
                            </label>
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_block_on"
                              :disabled="DisabledUpdate(s)"
                              placeholder="00:00"
                              type="tel"
                              maxlength="4"
                              hide-details
                              suffix="UTC"
                              class="afl-input"
                              @keypress="$NumberOnly"
                              @keyup="schedules[sS].afl_block_on = ReformatTime(s.afl_block_on)"
                            />
                          </v-col>
                          <v-col cols="12" class="pb-3">
                            <label class="afl-input-label">
                              Landing Cycle
                            </label>
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_cycle_landing"
                              :disabled="DisabledUpdate(s)"
                              placeholder="i.e. : 1"
                              type="tel"
                              hide-details
                              suffix="Cycle"
                              class="afl-input"
                              @keypress="$NumberOnly"
                            />
                          </v-col>
                          <v-col cols="12">
                            <label class="afl-input-label">
                              {{ schedules[sS].aircraft_total_engine === 2 ? 'Engines Cycle' : 'Engine Cycle' }} ({{schedules[sS].aircraft_name}})
                            </label>
                          </v-col>
                          <v-col :cols="schedules[sS].aircraft_total_engine === 2 ? '6' : '12'" class="pb-3 pr-1">
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_cycle_eng1"
                              :disabled="DisabledUpdate(s)"
                              :placeholder="schedules[sS].aircraft_total_engine === 2 ? 'Left Engine' : 'Main Engine'"
                              type="tel"
                              hide-details
                              :suffix="schedules[sS].aircraft_total_engine === 2 ? 'Left' : 'Main Engine'"
                              class="afl-input"
                              @keypress="$NumberOnly"
                            />
                          </v-col>
                          <v-col cols="6" :class="schedules[sS].aircraft_total_engine !== 2 ? 'd-none' : ''" class="pb-3 pl-1">
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_cycle_eng2"
                              :disabled="DisabledUpdate(s)"
                              placeholder="Right Engine"
                              type="tel"
                              suffix="Right"
                              hide-details
                              class="afl-input"
                              @keypress="$NumberOnly"
                            />
                          </v-col>
                          <v-col cols="6" class="pb-3 pr-1">
                            <label class="afl-input-label">
                              Fuel Depart
                            </label>
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_fuel_dep"
                              :disabled="DisabledUpdate(s)"
                              placeholder="Fuel Depart"
                              type="tel"
                              hide-details
                              :suffix="schedules[sS].aircraft_fuel_metric"
                              class="afl-input"
                              @keypress="$NumberOnly"
                            />
                          </v-col>
                          <v-col cols="6" class="pb-3 pl-1">
                            <label class="afl-input-label">
                              Fuel Arrive
                            </label>
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_fuel_arr"
                              :disabled="DisabledUpdate(s)"
                              placeholder="Fuel Arrive"
                              type="tel"
                              hide-details
                              :suffix="schedules[sS].aircraft_fuel_metric"
                              class="afl-input"
                              @keypress="$NumberOnly"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-switch v-model.lazy="schedules[sS].afl_refueling" label="Aircraft refueling ?" :disabled="DisabledUpdate(s)" />
                          </v-col>
                          <v-col v-if="schedules[sS].afl_refueling" cols="12">
                            <label class="afl-input-label">
                              Refueling
                            </label>
                            <v-radio-group
                              v-model="schedules[sS].afl_refueling_type"
                              :disabled="DisabledUpdate(s)"
                              class="afl-input-radio"
                              row
                            >
                              <v-radio
                                label="Before Takeoff"
                                value="before"
                              ></v-radio>
                              <v-radio
                                label="After Landing"
                                value="after"
                              ></v-radio>
                            </v-radio-group>
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_refueling_nominal"
                              :disabled="DisabledUpdate(s)"
                              placeholder="Uplift Nominal"
                              type="tel"
                              hide-details
                              prefix="+"
                              suffix="L"
                              class="afl-input pt-0 mb-4"
                              @keypress="$NumberOnly"
                            />
                          </v-col>
                          <v-col cols="6" class="pb-3 pr-1">
                            <label class="afl-input-label">
                              Taxi Time
                            </label>
                            <v-select
                              v-model.lazy="schedules[sS].afl_taxi_decimal"
                              :items="['0.00', '0.05', '0.10', '0.15', '0.20', '0.25', '0.30']"
                              :disabled="DisabledUpdate(s)"
                              placeholder="Taxi Time"
                              hide-details
                              class="afl-input"
                            />
                          </v-col>
                          <v-col cols="6" class="pb-3 pl-1">
                            <label class="afl-input-label">
                              Cargo
                            </label>
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_cargo_weight"
                              :disabled="DisabledUpdate(s)"
                              placeholder="Cargo"
                              type="tel"
                              hide-details
                              suffix="Kg"
                              class="afl-input"
                              @keypress="$NumberOnly"
                            />
                          </v-col>

                          <v-col cols="12">
                            <label class="afl-input-label">
                              Passengers
                            </label>
                          </v-col>
                          <v-col cols="3" class="afl-col-pax first pb-3">
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_pass_adult"
                              :disabled="DisabledUpdate(s)"
                              placeholder="A"
                              type="tel"
                              prepend-inner-icon="mdi-account-tie font-20 pr-2"
                              hide-details
                              class="afl-input afl-input-pax"
                              @keypress="$NumberOnly"
                            />
                          </v-col>
                          <v-col cols="3" class="afl-col-pax pb-3">
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_pass_child"
                              :disabled="DisabledUpdate(s)"
                              placeholder="C"
                              type="tel"
                              prepend-inner-icon="mdi-human-male-child font-20 pr-2"
                              hide-details
                              class="afl-input afl-input-pax"
                              @keypress="$NumberOnly"
                            />
                          </v-col>
                          <v-col cols="3" class="afl-col-pax pb-3">
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_pass_baby"
                              :disabled="DisabledUpdate(s)"
                              placeholder="I"
                              type="tel"
                              prepend-inner-icon="mdi-baby-carriage font-20 pr-2"
                              hide-details
                              class="afl-input afl-input-pax"
                              @keypress="$NumberOnly"
                            />
                          </v-col>
                          <v-col cols="3" class="afl-col-pax last pb-3">
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_pass_crew"
                              :disabled="DisabledUpdate(s)"
                              placeholder="CR"
                              type="tel"
                              prepend-inner-icon="mdi-account-tie-hat font-20 pr-2"
                              hide-details
                              class="afl-input afl-input-pax"
                              @keypress="$NumberOnly"
                            />
                          </v-col>

                          <v-col cols="12" class="pb-3">
                            <label class="afl-input-label">
                              Total Weight
                            </label>
                            <v-text-field
                              v-model.lazy="schedules[sS].afl_total_weight"
                              :disabled="DisabledUpdate(s)"
                              placeholder="Total Weight"
                              type="tel"
                              hide-details
                              suffix="Kg"
                              class="afl-input"
                              @keypress="$NumberOnly"
                            />
                          </v-col>

                          <v-col cols="12" class="pb-3 px-0">
                            <label class="afl-input-label">
                              Notes
                            </label>
                            <v-textarea
                              v-model.lazy="schedules[sS].notes"
                              rows="3"
                              placeholder="Write something here..."
                              auto-grow
                              :disabled="DisabledUpdate(s)"
                              hide-details
                              class="afl-notes"
                            />
                          </v-col>
                          <v-col v-if="!DisabledUpdate(s) && parseInt(s.afl_signedby_id)" cols="12" class="py-3">
                            <v-alert
                              text
                              dense
                              color="grey"
                              icon="mdi-clock-fast"
                              class="alert-validation"
                            >
                              Your flight information is being checked by the CRD team. If you notice any mistakes, you can still correct them. Once the team has finished checking, if there are any errors, you can reach out to the CRD for assistance by clicking on the button down bellow.
                            </v-alert>
                          </v-col>
                          <v-col cols="12" class="text-center">
                            <v-btn v-if="!DisabledUpdate(s)" @click.prevent="UpdateSchedule(s, ['afl_takeoff', 'afl_landing', 'afl_takeoff_engine_start', 'afl_landing_engine_off', 'afl_refuling_type', 'afl_refueling_value', 'afl_fuel_dep', 'afl_fuel_arr', 'taxi_decimal', 'afl_cargo_weight', 'afl_total_weight', 'notes'])" color="error"
                              :disabled="
                                (
                                  !String(schedules[sS].afl_takeoff) ||
                                  !String(schedules[sS].afl_landing) ||
                                  !String(schedules[sS].afl_takeoff_engin_start) ||
                                  !String(schedules[sS].afl_landing_engine_stop) ||
                                  !String(schedules[sS].afl_cycle_eng1) ||
                                  !String(schedules[sS].afl_fuel_dep) ||
                                  !String(schedules[sS].afl_fuel_arr) ||
                                  !String(schedules[sS].afl_refueling_value)
                                ) || DisabledUpdate(s)
                              "
                              class="rounded-lg text-capitalize fw-500 px-4 py-2 mt-4 mb-3">
                              {{ DisabledUpdate(s) && parseInt(s.afl_signedby_id) ? 'Data Saved' : !DisabledUpdate(s) && parseInt(s.afl_signedby_id) ? 'Update Data' : 'Submit Data' }}
                            </v-btn>
                            <v-btn v-else @click.prevent="ContactCRD('628112079597')" outlined color="error" class="rounded-lg text-capitalize fw-500 px-4 py-2 mt-4 mb-3">
                              Contact CRD
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <!-- Simulator logbook for pilot and training assistant -->
                  <div v-else class="text-left mt-3">
                    <v-icon :color="
                      parseInt(s.afl_signedby_id) ?
                        'secondary' :
                        $moment().format('YYYY-MM-DD HH:mm') >= $moment(s.duty_local_date + ' ' + s.etd_local_time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') && $moment().format('YYYY-MM-DD HH:mm') <= $moment(s.duty_local_date + ' ' + s.eta_local_time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm') ?
                          'warning' :
                          'error'
                      "
                      class="align-middle mr-2">
                      {{ parseInt(s.afl_signedby_id) ? 'mdi-check-circle' : 'mdi-information' }}
                    </v-icon>
                    <span class="font-14 grey--text align-middle">
                      {{ GetSimStatus(s) }}
                    </span>
                  </div>
                </v-card>
                <div v-if="duty" class="end-duty-container d-flex fill-width no-gutters align-center justify-space-between pt-2 mb-6">
                  <v-col cols="6" class="font-12 fw-600" style="color:#7C8591">
                    20m after last flight
                  </v-col>
                  <v-col cols="6" class="end-duty">
                    <v-chip small color="error" class="fw-600">
                      End : {{ duty.end }} LT
                    </v-chip>
                  </v-col>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { getMessaging, onMessage } from 'firebase/messaging'
import Swal from 'sweetalert2'

let countInterval = null
let updateTimeout = null
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: vm => ({
    dialogDutyDate: false,
    aflLoading: false,
    afl: null,
    schedules: [],
    utcTime: null,
    dutyDate: null,
    duty: null,
    phoneContact: null
  }),
  computed: {
  },
  watch: {
    value (v) {
      if (v) {
        const dt = this.$route.query.scheduleDate || ''
        this.dutyDate = this.$localDT(dt, 'datedefault')
        if (countInterval) {
          clearInterval(countInterval)
        }
        countInterval = setInterval(() => {
          this.SyncUTC()
        }, 1000)
      } else {
        clearInterval(countInterval)
      }
    },
    dutyDate (dt) {
      this.LOAD_SCHEDULES()
    }
  },
  created () {
    this.SyncUTC()
    onMessage(getMessaging(), (payload) => {
      this.$toastNotif({
        message: payload.notification.body,
        type: 'info'
      })
    })
  },
  methods: {
    FlightType (source, list) {
      const data = {}
      list.forEach((d) => {
        if (source === d.value) {
          Object.assign(data, d)
        }
      })

      return data
    },
    GetSimStatus (s) {
      let status
      if (parseInt(s.afl_signedby_id)) {
        status = 'Completed'
      } else {
        if (
          (this.$moment().format('YYYY-MM-DD HH:mm') >= this.$moment(s.duty_local_date + ' ' + s.etd_local_time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')) &&
          (this.$moment().format('YYYY-MM-DD HH:mm') <= this.$moment(s.duty_local_date + ' ' + s.eta_local_time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm'))
        ) {
          status = 'In Progress'
        } else {
          status = 'Unverified'
        }
      }
      return status
    },
    DisabledUpdate (s) {
      if (this.$store.state.admin) {
        return false
      } else {
        if (s.afl_validatedby_id) {
          return true
        } else {
          return false
        }
      }

      // const passedEta = new Date(s.duty_local_date + 'T' + s.eta_local_time + ':00') > new Date()
      // return parseInt(s.afl_validatedby_id) ? true : (passedEta || false)
    },
    UpdateSchedule (s, key) {
      Swal.fire({
        title: 'Confirm Flight Details?',
        text: 'Please make sure your data is filled correctly.',
        showCancelButton: true,
        confirmButtonText: 'Complete Flight',
        confirmButtonColor: '#E33F5A',
        cancelButtonColor: '#C1C1C1',
        showClass: {
          popup: 'animate__animated animate__slideInUp animate__faster'
        },
        hideClass: {
          popup: 'animate__animated animate__slideOutDown animate__faster'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          if (updateTimeout) {
            clearTimeout(updateTimeout)
          }
          updateTimeout = setTimeout(() => {
            const id = parseInt(s.id)
            const findIndex = this.schedules.findIndex(r => parseInt(r.id) === id)
            // ID Founded
            if (findIndex > -1) {
              var params = []
              key.forEach((data, index) => {
                this.schedules[findIndex][data] = s[data]
                params = Object.assign({}, this.schedules[findIndex])
                params.afl_flighttime = this.ParseFlightTime(s).flighttime
                if (params.flight_type !== 'simulator') {
                  params.afl_cycle_landing = params.afl_cycle_landing ? params.afl_cycle_landing : 1
                } else {
                  params.uniqcode = '000-000'
                  params.afl_takeoff = '00:00'
                  params.afl_landing = '00:00'
                  params.afl_flighttime = '0.0'
                  params.afl_taxi_decimal = '0.00'
                  params.flight_logbook = '0'
                }
                if (!params.afl_refueling) {
                  params.afl_refueling_nominal = 0
                }
                params.afl_signedby_id = 0
                const fname = params.aircraft_name || 'NONE'
                if (!parseFloat(params.afl_taxi_decimal)) {
                  if (fname.match(/CESSNA/)) {
                    params.afl_taxi_decimal = 0.10
                  } else if (fname.match(/PILATUS/)) {
                    params.afl_taxi_decimal = 0.05
                  }
                }
                params.afl_signedby_id = this.$store.state.me ? this.$store.state.me.id : s.pilot
              })

              this.$store.dispatch('AFL_SCHEDULES_PROCESS', params).then((res) => {
                if (res.status) {
                  if (res.data.data.id) {
                    this.$toastNotif({
                      message: 'Flight information data saved. please wait PPC to validate your flight data',
                      type: 'success'
                    })
                    this.$router.push('/history').then(() => {
                      this.$router.go(0)
                    })
                  }
                } else {
                  this.$toastNotif({
                    message: 'Oops.. Something went wrong',
                    type: 'error'
                  })
                }
              })
            }
          }, 300)
        } else {
          Swal.close()
        }
      })
    },
    touchTo (direction) {
      if (direction === 'left') {
        this.dutyDate = this.$DateAddDays(this.dutyDate, 1)
      } else if (direction === 'right') {
        this.dutyDate = this.$DateAddDays(this.dutyDate, -1)
      }
    },
    ParseFlightTime (d) {
      const result = {
        flighttime: 0,
        airtime: 0,
        taxi: parseFloat(d.afl_taxi_decimal).toFixed(2)
      }
      // result.flighttime = (this.validTime(d.afl_takeoff_engine_start) && this.validTime(d.afl_landing_engine_stop)) ? (this.$dTime(d.afl_takeoff_engine_start, d.afl_landing_engine_stop, 1)) : 0
      result.airtime = (this.validTime(d.afl_takeoff) && this.validTime(d.afl_landing)) ? this.$dTime(d.afl_takeoff, d.afl_landing, 1) : 0
      // if (this.validTime(d.afl_takeoff_engine_start) && this.validTime(d.afl_takeoff) && this.validTime(d.afl_landing) && this.validTime(d.afl_landing_engine_stop)) {
      //   result.taxi = this.$dTime(d.afl_takeoff_engine_start, d.afl_takeoff, 1) + this.$dTime(d.afl_landing, d.afl_landing_engine_stop, 1)
      // }
      // result.taxi = parseFloat(result.taxi).toFixed(1)
      result.flighttime = parseFloat(parseFloat(result.taxi) + parseFloat(result.airtime)).toFixed(1)
      result.airtime = parseFloat(result.airtime).toFixed(1)
      return result
    },
    validTime (t) {
      return this.$IsValidTime(t)
    },
    LOAD_SCHEDULES () {
      const dt = this.dutyDate
      this.aflLoading = true
      this.duty = null
      this.$store.dispatch('PILOT_SCHEDULES_GET', { q: '?sort=ASC&from=' + (dt || '') + '&to=' + (dt || '') }).then((res) => {
        if (res.status) {
          this.schedules = res.data.data.data || []
          this.CountDutyTime()
        }
        this.aflLoading = false
      })
    },
    CountDutyTime () {
      this.duty = null
      if (this.schedules.length) {
        const firstDuty = Object.assign({}, this.schedules[0])
        const lastDuty = Object.assign({}, this.schedules[this.schedules.length - 1])
        this.duty = {
          start: this.$moment(firstDuty.etd_local_time, 'HH:mm').subtract(40, 'minutes').format('HH:mm'),
          end: this.$moment(lastDuty.eta_local_time, 'HH:mm').add(20, 'minutes').format('HH:mm')
        }
      }
    },
    AFLSchedulesGet () {
      this.aflLoading = true
      this.schedules = []
      this.ShowAFLDetail({ date_afl: this.afl?.date_afl }, true)
      if (parseInt(this.fleet)) {
        const queries = ('?fleet=' + this.fleet) + (parseInt(this.afl.id) ? ('&afl=' + parseInt(this.afl.id)) : (this.afl.date_afl ? ('&date=' + this.afl.date_afl) : ''))
        this.$store.dispatch('flight/AFL_SCHEDULES_GET', queries + '&crew=' + parseInt(this.$store.state.me.id))
          .then((res) => {
            if (res) {
              if (res.aflData) {
                this.afl = Object.assign({}, res.aflData)
              }
              this.schedules = (res.data || []).map((r) => {
                r.flighttime_decimal = parseFloat(r.flighttime_decimal || 0) // estimate ft from schedule
                r.afl_flighttime = parseFloat(r.afl_flighttime || 0)
                r.afl_fuel_arr = parseFloat(r.afl_fuel_arr || 0)
                r.afl_fuel_dep = parseFloat(r.afl_fuel_dep || 0)
                return r
              })
            }
            this.aflLoading = false
          })
      } else {
        this.aflLoading = false
      }
    },
    ShowAFLDetail (p) {
      this.afl.data = {
        id: p ? parseInt(p.id || null) : null,
        reg_number: p ? (p.reg_number || '') : '',
        date_afl: p ? (p.date_afl || this.$localDT(null, 'datedefault')) : this.$localDT(null, 'datedefault'),
        fleet: p ? (parseInt(p.fleet) || parseInt(this.fleet)) : parseInt(this.fleet),
        assigned_by: p ? parseInt(p.assigned_by || 0) : 0, // Last Flight Captain ID
        confirmed_by: p ? parseInt(p.confirmed_by || 0) : 0, // AFL Checker / PPC
        approved_by: p ? parseInt(p.approved_by || 0) : 0, // AFL Approval / PPC Manager
        notes: p ? (p.notes || '') : '',
        docs: p ? this.$StrToArray(p.docs) : [],
        status: p ? parseInt(p.status || 0) : 0 // 0 Not Confirmed, 1 Confirmed By PPC, 2 Approved By PPC (Complete)
      }
    },
    ReformatTime (t) {
      if (t) {
        if (t.length === 4 && t[2] !== ':') {
          return t.substr(0, 2) + ':' + t.substr(2, 2)
        }
      }
      return t
    },
    IsValidTime (t, tBefore) {
      const time = this.$IsValidTime(t)
      const timeBefore = tBefore ? this.$IsValidTime(tBefore) : false
      if (time && timeBefore) {
        return this.$CompareTime(t, tBefore)
      }
      return time
    },
    SyncUTC () {
      const t = this.$GetTimeUTC(true)
      this.utcTime = t.time
    },
    OpenWhatsapp () {
      const phone = this.phoneContact
      const message = 'Hello... I need help with...'
      window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${message}`, 'parent')
    },
    ContactCRD (phone) {
      this.phoneContact = phone
      this.$SweetAlert({
        title: 'Need PPC Assistance?',
        caption: 'You will be redirected into Whatsapp message.',
        confirmText: 'Continue',
        onConfirm: this.OpenWhatsapp
      })
    },
    ContactOCC (phone) {
      this.phoneContact = phone
      this.$SweetAlert({
        title: 'Need OCC Assistance?',
        caption: 'You will be redirected into Whatsapp message.',
        confirmText: 'Continue',
        onConfirm: this.OpenWhatsapp
      })
    },
    OpenURL (href) {
      const a = document.createElement('a')
      a.setAttribute('target', '_blank')
      a.setAttribute('href', href)
      a.click()
    }
  }
}
</script>

<style lang="scss">
@import './../assets/scss/global-vars';
@import './../assets/scss/fonts';

.afl-area {
  background-color: $primary;
  overflow: hidden;
  .afl-toolbar{
    height: auto !important;
    box-shadow: none !important;
    padding: 62px 30px 0;
    .v-toolbar__content{
      height: auto !important;
      padding: 0 !important;
      .back-btn{
        background-color: transparent !important;
        color: $white;
        padding: 0;
        box-shadow: none;
        span:not(.v-btn__content){
          margin-left: 12px;
          text-transform: capitalize;
        }
      }
      .v-btn:not(.back-btn){
        color: $white;
        justify-content: end;
      }
    }
  }
  .afl-card{
    background-color: $white;
    border-radius: 50px 50px 0 0;
    margin-top: 115px !important;
    padding: 0 !important;
    .afl-card-header{
      padding: 30px 30px 20px;
      color: $primary;
      .duty-date-title{
        color: $primary !important;
        background-color: transparent !important;
        &:focus{
          &:before{
            background-color: transparent;
            opacity: 1;
          }
        }
        span{
          @include font-size(18px);
          font-weight: 700;
          text-transform: capitalize;
        }
      }
      .utc-counter{
        @include font-size(16px);
        font-weight: 700;
        margin-top: 4px;
      }
      .start-duty{
        text-align:left;
        border-right: 1px solid $grey;
      }
      .col-6:not(.start-duty){
        @include font-size(12px);
        font-weight: 600;
        color: $grey;
        text-align: right;
      }
    }
    .afl-card-body{
      padding: 0 30px;
      overflow: hidden auto;
      height: calc(100vh - 315px);
      .duty-card{
        padding: 12px;
        border: 1px solid #EEF0EB;
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        margin-bottom: 18px;
        .aircraft-reg{
          @include font-size(16px);
          color: $primary;
          font-weight: 600;
        }
        .etd-time{
          @include font-size(16px);
          font-weight: 500;
          margin-bottom: 2px;
        }
        .afl-col-pax{
          &:not(.first, .last){
            .v-input__slot{
              border-radius: 0;
            }
          }
          &.first{
            .v-input__slot{
              border-radius: 10px 0 0 10px;
            }
          }
          &.last{
            .v-input__slot{
              border-radius: 0 10px 10px 0;
            }
          }
        }
        .afl-input-label{
          padding-top: 6px;
          @include font-size(14px);
          font-weight: 500;
        }
        .afl-input{
          padding-top: 4px;
          margin-top: 0;
          &:not(.afl-input-pax){
            .v-input__slot{
              border-radius: 10px;
            }
          }
          .v-input__slot{
            padding: 6px 12px;
            background: linear-gradient(0deg, #0000000f, #0000000f), #F6F8FB;
            &:before,
            &:after{
              border: 0;
            }
            .v-select__slot,
            .v-text-field__slot{
              @include font-size(14px);
              font-family: $font-family;
              .v-text-field__suffix{
                font-weight: 600;
              }
            }
          }
        }
        .afl-input-radio{
          margin-top: 0;
          padding-top: 6px;
          padding-bottom: 2px;
          .v-radio{
            align-self: center;
            width: 50%;
            padding-right: 4px;
            margin: 0;
            .v-label{
              @include font-size(13px);
            }
          }
          .v-messages{
            display: none;
          }
        }
        .afl-notes{
          padding-top: 4px;
          margin-top: 0;
          @include font-size(14px);
          .v-input__slot{
            height: auto !important;
            border-radius: 10px;
            padding: 6px 12px;
            &::before,
            &::after{
              display: none;
            }
          }
        }
      }
      .end-duty-container{
        >.end-duty{
          text-align:right;
          border-left: 1px solid $grey;
        }
        >.col-6:not(.end-duty){
          @include font-size(12px);
          font-weight: 600;
          color: $grey;
          text-align: left;
        }
      }
      .alert-validation{
        border-radius: 10px;
        .v-alert__content{
          font-family: $font-family;
          @include font-size(13px);
          font-weight: 400 !important;
        }
      }
    }
  }
  .v-expansion-panel-content__wrap {
    padding-left: 3px!important;
    padding-right: 3px!important;
  }
  .v-expansion-panel--active {
    .v-expansion-panel-header {
      padding-top: 0px!important;
      padding-bottom: 0px!important;
      min-height: 30px!important;
      &.v-expansion-panel-header--active {
        min-height: 45px!important;
      }
    }
  }
  .v-text-field--solo {
    .v-input__control {
      .v-input__slot {
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 7%), 0px 2px 2px 0px rgb(0 0 0 / 4%), 0px 1px 5px 0px rgb(0 0 0 / 7%)!important;
      }
    }
  }
}
.v-application {
  .error--text {
    input {
      color:#ff0000!important;
    }
  }
  .v-text-field {
    .v-input__control {
      >.v-input__slot {
        height: 45px;
        background-color:#ededed;
      }
    }
  }
}
.v-expansion-panel--active {
  .v-expansion-panel-content__wrap {
    &::after {
      position:absolute;
      display:flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
      font-size:3rem;
      color:rgba(119, 118, 118, 0.2);
      left:0px;
      top:-5px;
      width:100%;
      height:calc(100% - 52px);
      background-color:rgba(255, 255, 255, .5);
      z-index:1;
      transition: all .3s ease;
    }
  }
}
.schedule-locked {
  .v-expansion-panel--active {
    .v-expansion-panel-content__wrap {
      position:relative;
      &::after {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
        color:rgba(209, 12, 12, 0.2);
        content: "Locked";
      }
    }
  }
  &.schedule-validated {
    .v-expansion-panel--active {
      .v-expansion-panel-content__wrap {
        position:relative;
        &::after {
          visibility: visible;
          pointer-events: all;
          opacity: 1;
          height:100%;
          color:rgba(2, 172, 2, 0.2);
          content: "Validated";
        }
      }
    }
  }
}
</style>
